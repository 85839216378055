<template>

  <div class="addDing is-full-height is-overflow-y-scroll">
  
    <back-button class="ml-2"/> 
    <ImageUpload :startImageArray="item.pictures" @imageUploadChangedPreviewImages="handleImageEdits"/>
     
    <div class="is-flex is-flex-direction-column is-align-items-center is-full-width">

      <div class="is-full-width broad-input m-1">
        <label for="nameinput" class="is-float-left has-text-weight-bold">Name</label>
        <input
            v-model="item.name"
            @input="handleItemNameUpdate"
            minlength="4"
            class="input is-rounded"
            placeholder="item name"
          />
      </div>

      <div class="is-full-width is-flex is-flex-wrap-wrap broad-input mt-2 mb-2 is-align-items-baseline">
          <RootLocationPill :item="item.insideof ? {'id':item.insideof} : item"/> 
          <ContainerPath v-if="item['location.insideofArray']" :containerArray="containerArray"/>
      </div>

      <AddDingLocation :item="item" @locationUpdate="handleLocationUpdate"/>

      <div class="is-full-width broad-input mt-2 mb-2">
        <textarea v-model="item.description" class="textarea" placeholder="description" rows="4"></textarea>
      </div>

      <div class="is-full-width broad-input mt-2 mb-2">
        <label for="categoryselect" class="is-float-left has-text-weight-bold" style="width:1px">Category</label>  
        <div id="categoryselect" class="select is-full-width">
          <select class="is-full-width" v-model="category">
            <option v-for="key in $store.state.ui.categories" :key="key" :default="key == 'all'" :value="key">{{key.toUpperCase()}}</option>
          </select>
        </div>
      </div>

      <div class="is-full-width broad-input mb-2">
          <label for="taginput" class="is-float-left has-text-weight-bold">Specifics / Tags</label>
          <Tagsinput
            v-model="item.tags"
            id="taginput" 
            placeholder="add specific tags"
            @tagInputUpdate="handleUpdateTagInput" 
            @removeTag="removeTag($event)"
            @addTag="handleAddTag"
            :searchResults="tagInputAutocomplete"
            :filterDisplayedTags="tagCanBeShownInTagInput"
            ></Tagsinput>
      </div>

      <div class="is-flex is-85-pc-width is-justify-content-center">
            <div class="broad-input mb-2 is-third-width m-1">
              <label class="is-float-left has-text-weight-bold">Length (cm)</label>
              <input class="input is-rounded" type="number" inputmode="numeric" step="1" min="0" v-model="item.length" placeholder="length"/>
            </div>

            <div class="broad-input mb-2 is-third-width m-1">
              <label class="is-float-left has-text-weight-bold">Width (cm)</label>
              <input class="input is-rounded" type="number" inputmode="numeric" step="1" min="0" v-model="item.width" placeholder="width"/>
            </div>

            <div class="broad-input mb-2 is-third-width m-1">
              <label class="is-float-left has-text-weight-bold">Height (cm)</label>
              <input class="input is-rounded" type="number" inputmode="numeric" step="1" min="0" v-model="item.depth" placeholder="height"/>
            </div>
      </div>
  
      <!-- <div class="is-full-width broad-input" v-if="shouldBeShownByCategory('sizes',category,$store)">
        <label class="is-float-left has-text-weight-bold">Sizes</label>
        <Tagsinput 
          :modelValue="sizetags" 
          placeholder="add sizes"
          @addTag="addTag('size:'+$event)"
          @removeTag="removeTag('size:'+$event)"
          ></Tagsinput>
      </div> -->

      <div class="is-flex is-85-pc-width is-justify-content-center">
        <div class="is-full-width broad-input mb-2 mr-1" v-if="$route.query.showGeolocation == undefined">
          <label class="is-float-left has-text-weight-bold">quantity/count</label>
          <input class="input is-rounded" type="Number" inputmode="numeric" step="1" min="1" v-model="item.count" placeholder="count"/>
        </div>

        <div class="is-full-width broad-input mb-2">
          <label class="is-float-left has-text-weight-bold">Weight (kg)</label>
          <input class="input is-rounded" type="number" inputmode="decimal" step="0.01" min="0" v-model="item.weight" placeholder="add weight"/>
        </div>
      </div>

  <div class="is-flex is-85-pc-width is-justify-content-center">
      <div class=" broad-input mb-2 mr-1 ml-0">
        <label class="is-float-left has-text-weight-bold">Buying Price (EUR)</label>
        <input class="input is-rounded" type="number" inputmode="decimal" step="0.01" min="0" v-model="item.price" placeholder="add price"/>
        <!-- <Tagsinput 
          :modelValue="[item.price]" 
          placeholder="add price"
          @addTag="item.price = $event"
          @removeTag="item.price = null"
          limit=1
          inputtype="Number"
          inputstep="0.01"
          inputmin="0"
          ></Tagsinput> -->
      </div>
    
      <div class=" broad-input mb-2">
        <label class="is-float-left has-text-weight-bold">Current Value (EUR)</label>
        <input class="input is-rounded" type="number" inputmode="decimal" step="0.01" min="0" v-model="item.value" placeholder="add value"/>
        <!-- <Tagsinput 
          :modelValue="[item.value]" 
          placeholder="add value"
          @addTag="item.value = $event"
          @removeTag="item.value = null"
          limit=1
          inputtype="Number"
          inputstep="0.01"
          inputmin="0"
          ></Tagsinput> -->
      </div>

  </div>

      <div class="is-full-width broad-input mb-2">
        <label class="is-float-left has-text-weight-bold">COUNTRY OF ORIGIN</label>
        <input class="input is-rounded" type="TEXT" v-model="item.countryoforigin" placeholder="country code (DE/CN/US etc.)"/>
      </div>

      <div class="is-flex is-85-pc-width is-justify-content-center">
        <div class="is-half-width broad-input mb-2 mr-1">
          <label class="is-float-left has-text-weight-bold">BILLING REFERENCE</label>
          <input class="input is-rounded" type="TEXT" v-model="item.billingreference" placeholder="unique reference for bookkeeping/abrechnung"/>
        </div>

        <div class="is-half-width broad-input mb-2">
          <label class="is-float-left has-text-weight-bold">INVOICE DATE</label>
          <input class="input is-rounded" type="TEXT" v-model="item.invoicedate" placeholder="date of bill/invoice"/>
        </div>
      </div>

      <div class="m-5">
        <PossessionPills :item="item" 
          @ownedClicked="ownedEditable = !ownedEditable"
          @possessedClicked="possessedEditable = !possessedEditable"
          @visibleClicked="visibleEditable = !visibleEditable"
        />
      </div>
      
      <div class="is-full-width broad-input mb-2" v-if="ownedEditable">
        <label class="is-float-left has-text-weight-bold">OWNED BY</label>
        <Tagsinput 
          :modelValue="item.owned" 
          placeholder="owner(s)"
          @addTag="item.owned.includes($event) ? null : item.owned.push($event)"
          @removeTag="item.owned = item.owned.filter((o)=>o !== $event)"
          inputtype="String"
          ></Tagsinput>
      </div>

      <div class="is-full-width broad-input mb-2" v-if="possessedEditable">
        <label class="is-float-left has-text-weight-bold">LENT TO / BORROWED BY</label>
        <Tagsinput 
          :modelValue="item.possessedby" 
          placeholder="lent to / borrowed by"
          @addTag="item.possessedby.includes($event) ? null : item.possessedby.push($event)"
          @removeTag="item.possessedby = item.possessedby.filter((o)=>o !== $event)"
          inputtype="String"
          ></Tagsinput>
      </div>

      <div class="is-full-width broad-input mb-2" v-if="visibleEditable">
        <label class="is-float-left has-text-weight-bold">VISIBLE TO</label>
        <Tagsinput 
          :modelValue="item.visible2" 
          placeholder="lent to / borrowed by"
          @addTag="item.visible2.includes($event) ? null : item.visible2.push($event)"
          @removeTag="item.visible2 = item.visible2.filter((o)=>o !== $event)"
          inputtype="String"
          ></Tagsinput>
      </div>

    </div>

    <button :class="{'button m-2 mt-3 mb-4 has-background-purple-1 is-rounded has-text-light has-border-black':true, 'is-loading':isLoading}" :disabled="isLoading" @click="handleEditItem">UPDATE ITEM</button>
    
    <button class="button m-2 mt-3 mb-4 has-background-light is-rounded has-text-dark has-border-black" @click="$router.push('/')">CANCEL</button>

  </div>
  
</template>

<script>
import ImageUpload from '@/components/ItemForm/ImageUpload.vue'
import AddDingLocation from '@/components/ItemForm/addDingLocation.vue'
import Tagsinput from '@/components/Template/TagInput'
import BackButton from '@/components/Template/BackButton.vue';
import RootLocationPill from '@/components/Location/RootLocationPill'
import ContainerPath from '@/components/Location/ContainerPath'
import PossessionPills from '@/components/ItemForm/PossessionPills';

import { ref, onMounted, onUnmounted, onBeforeMount} from "@vue/runtime-core";
import { useStore } from "vuex";
import {useRoute, useRouter} from "vue-router"
import {shouldBeShownByCategory} from '@/helpers.js';


export default {
  name: 'AddDing',
  components: {
    BackButton,
    ImageUpload,
    AddDingLocation,
    Tagsinput,
    RootLocationPill,
    ContainerPath,
    PossessionPills,
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const item = ref({});

    const category = ref('all');

    const itemNameInput = ref(null);
    const tagInputAutocomplete = ref([]);

    const isLoading = ref(false);
    
    //const newsizetag = ref('');
    //const sizetags = computed(()=>{return item.value.tags?.filter((t)=>t.startsWith("size:"))})
    
    const containerArray = ref(item.value['location.insideofArray']?.map((i)=>{ return {id:i} }))

    const ownedEditable = ref(false);
    const possessedEditable = ref(false);
    const visibleEditable = ref(false);

    onBeforeMount(async ()=>{
      if (route.query.id){
        item.value = await store.dispatch('getItem',route.query.id);
        await store.dispatch("setSelected",{key:"item",value:JSON.parse(JSON.stringify(item.value))});
        if (!item.value.tags){item.value.tags = []}
        if (!item.value.pictures){item.value.pictures = []}
        if (item.value.price){item.value.price = item.value.price.replace("$","")}
        if (item.value.value){item.value.value = item.value.value.replace("$","")}
      }
      else{
        router.push('/')
      }
    })

    onMounted(()=>{
      store.dispatch("hideTabBar");
      fillContainerArray();
    })

    onUnmounted(()=>{
      store.dispatch("showTabBar");
    })

    const handleEditItem = async ()=>{
      console.log("editItem with item", item.value);
      isLoading.value = true;
      let result = await store.dispatch("editItem",{item:item.value});
      console.log("editItem RESULT",result);
      
      isLoading.value = false;
      if (result.status == 200){
        // clear/reload or show success screen after clearing
        router.push(('/Detail?id='+item.value.id));
        item.value = {};
        //router.go(-1);
        return
      }
    }

    const handleImageEdits = async (e)=>{
      let newPreviewImageArray = e
      item.value.pictures = newPreviewImageArray;
    }

    const handleUpdateTagInput = async (e)=>{
        if (e.length > 3){
          let autocomplete = await store.dispatch("searchTags",{tag:e});
          tagInputAutocomplete.value = autocomplete.map((i)=>i.tag);
        }
        else {
          clearAutocomplete(tagInputAutocomplete)
        }
    }
   
    const addTag = async (tag)=> {
      if (!item.value.tags.includes(tag)){
        item.value.tags.push(tag);
      }
    }

    const removeTag = async (tag)=> {
      item.value.tags.splice(item.value.tags.indexOf(tag), 1);
    }

    // TODO: refactor into helpers.js 
    /*
    this is a proof of concept function to filter out specific key-value pairs 
    from being displayed.
    e.g.: tagsThatHaveTheirOwnFormField = ["size:","dimension:"]
    */
    const tagsThatHaveTheirOwnFormfield = []
    const tagCanBeShownInTagInput = ({tag})=>{
        if(tagsThatHaveTheirOwnFormfield.some((t)=>tag.startsWith(t))){
          return false
        }
        else{
          return true
        }
    }

    const clearAutocomplete = (source) => {
       source.value = []
    }

    const handleAddTag = ()=>{
      clearAutocomplete(tagInputAutocomplete)
    }

    const handleLocationUpdate = (e)=>{
      console.log("location update event received",e);
      if (!e.insideof && !e.atLocation){
        console.log("location empty. will empty field in item ");
        item.value.insideof = null;
        item.value.atLocation = null;
        return;
      }
      if (e.insideof){
        item.value.insideof = e.insideof;
        item.value.atLocation = null;
      }
      else if (e.atLocation){
        item.value.insideof = null; /// TEST TEST TEST!
        item.value.atLocation = e.atLocation;
      }
      item.value["location.insideofArray"] = e["location.insideofArray"];
      fillContainerArray();
    }

    const handleCategoryUpdate = (e)=>{
      console.log("CATEGORY:",e.target.value);
      category.value = e.target.value;
    }

    // TODO: refactor into helper.js
    const fillContainerArray = () => {
        if (!item.value['location.insideofArray']){return}

        let allpromises = [];
        for (let id of item.value['location.insideofArray']){
          allpromises.push(store.dispatch('getItem',id));
        }
        Promise.all(allpromises).then((res)=>{
          containerArray.value = res;
        })
    }


    return {
        item,
        category,
        itemNameInput,
        handleAddTag,
        tagInputAutocomplete,
        handleUpdateTagInput,
        tagCanBeShownInTagInput,
        //sizetags,
        //newsizetag,
        clearAutocomplete,
        addTag,
        removeTag,
        handleLocationUpdate,
        handleCategoryUpdate,
        shouldBeShownByCategory,
        handleEditItem,
        handleImageEdits,
        containerArray,
        ownedEditable,
        possessedEditable,
        visibleEditable,
        isLoading,
    }
  }
}
</script>